*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

$cubic-ease: cubic-bezier(0.155, 0.81, 0.26, 0.995);

html, body, #root {
	position: relative;
	width: 100vw !important;
	overflow-x: hidden;
	height: auto !important;
	// scroll-behavior: smooth;
}

#root {
	height: 100vh !important;
	overflow: hidden;
}

// body {
// 	height: 100vh !important;
// 	overflow: hidden;
// }

// .panel-wrap {
// 	height: 100vh;
// 	overflow: auto;
// }

*::selection {
	color: #F3F3F3;
	background-color: #89D329;
}

body {
  position: relative;
  background: #f1f1f1;
  width: 100%;
  font-family: "Neue Helvetica", Arial, Roboto, sans-serif;
  color: #F3F3F3;
  /* transition: 0.4s ease-in-out; */
  /* overflow: hidden; */
}

// .panels {
// 	font-family: sans-serif;
// 	scroll-snap-type: Y mandatory;
// 	scroll-snap-stop: always;
// 	// -webkit-overflow-scrolling: touch;
// 	overflow-y: scroll;
// 	height: 100vh;

// 	.section {
// 		scroll-snap-align: start;
// 	}
// }

#__next-prerender-indicator {
  display: none;
}

.panel-wrap {
	position: relative;
	z-index: 4;
	pointer-events: none;
}

.globe-wrap {
	position: fixed;
	top: auto;
	bottom: 25vh;
	height: 50vh;
	left: 0;
	width: 100%;
	max-width: 100%;
	z-index: 1;
	// transform: translateY(50%);
	transition: bottom 1s cubic-bezier(0.155, 0.81, 0.26, 0.995), top 1s cubic-bezier(0.155, 0.81, 0.26, 0.995), left 1s cubic-bezier(0.155, 0.81, 0.26, 0.995), opacity 1s cubic-bezier(0.155, 0.81, 0.26, 0.995);

	@media screen and (min-width: 600px) {
		top: 0;
		height: 100vh;
		bottom: auto;
		// z-index: 999;
		opacity: 0;
	}

	canvas {
		position: relative;
		// width: 100vw !important;
		max-width: 100% !important;
		max-height: 100% !important;
		width: 100% !important;
		height: 100% !important;
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
		z-index: 1;

	}
}

.globe-wrap[data-active="0"] {
	bottom: -25vh;
}

.globe-wrap[data-active="5"] {
	transform: none;
	bottom: 0;
}

@media screen and (min-width: 600px) {
	.fp-scrollable, .fp-scroller {
		height: 100%;
		overflow: visible;
	}

	.globe-wrap {
		transform: none;
		opacity: 1;
		max-width: 90% !important;
		max-height: 90% !important;
	}

	.globe-wrap[data-active="0"] {
		max-width: 100% !important;
		max-height: 100% !important;
		left: 0;
		top: 60vh;
	}
	.globe-wrap[data-active="5"] {
		top: auto;
		bottom: -5%;
		left: 21%;
	}
	.globe-wrap[data-active="1"] {
		top: 15vh;
		left: 5vw;
	}
	.globe-wrap[data-active="2"],
	.globe-wrap[data-active="3"],
	.globe-wrap[data-active="4"] {
		left: 28%;
		top: 5vh;
	}
}

@media screen and (min-width: 992px) {
	.globe-wrap {
		max-width: 100% !important;
		max-height: 100% !important;
	}
	.globe-wrap[data-active="0"] {
		left: 0;
		top: 60vh;
	}
	.globe-wrap[data-active="5"] {
		top: auto;
		bottom: -10%;
		left: 16%;
	}
	.globe-wrap[data-active="1"] {
		top: 0;
		left: 0;
	}
	.globe-wrap[data-active="2"],
	.globe-wrap[data-active="3"],
	.globe-wrap[data-active="4"] {
		left: 23%;
		top: 0;
	}
}

// #drag-icon {
// 	cursor: grab;
// }
// #drag-icon-child {
// 	animation: bounce 1s linear infinite;

// 	&.dragging {
// 		animation: none !important;
// 	}
// }

// #drag-icon:target,
// #drag-icon:focus {
// 	cursor: grabbing;
// }

// .draggable {
// 	position: fixed;
// 	top: 50%;
// 	left: 50%;
// 	transform: translate(-50%, -50%);
// 	z-index: 9999;
// 	width: 100%;
// 	max-width: 610px;
// 	display: none;

// 	@media screen and (min-width: 600px) {
// 		display: block;
// 	}
// }

.section {
	width: 100%;
	// min-height: 100vh;
	height: auto;

	& > * {
		transition: opacity 0.5s $cubic-ease;
		opacity: 0;
	}

	&.active > * {
		transition: opacity 0.5s $cubic-ease;
		opacity: 1;
	}

	@media screen and (min-width: 600px) {
		height: 100vh;
	}
}

.background {
	pointer-events: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
  width: 100%;
	height: 100vh;
  background-color: #073144;
	z-index: -4;
	opacity: 0;
	overflow: hidden;
	transition: all 0.25s 0.125s cubic-bezier(0.155, 0.81, 0.26, 0.995);

	@media screen and (min-width: 600px) {
		width: 35%;
	}

	&[data-active="0"] {
		position: fixed;
		top: 0;
		right: 0;
		height: 100vh !important;
		// background: url("https://bcs-map.parado.cz/section_1_bg.jpg") no-repeat center center/ cover;
	}

	&[data-active="0"][data-index="0"],
	&[data-active="1"][data-index="1"],
	&[data-active="2"][data-index="2"],
	&[data-active="3"][data-index="3"],
	&[data-active="4"][data-index="4"],
	&[data-active="5"][data-index="5"] {
		z-index: -3;
		opacity: 1;
		transition: all 0.25s 0s cubic-bezier(0.155, 0.81, 0.26, 0.995);
	}

	&[data-index="1"]::before {
		content: "";
		position: absolute;
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50vh;
		background: linear-gradient( rgba(243,243,243,0), #4D1132) !important;
	}

	&[data-index="2"]::before {
		content: "";
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url("https://bcs-map.parado.cz/molecule_bg.png") no-repeat center center/contain;

		@media screen and (min-width: 600px) {
			background: url("https://bcs-map.parado.cz/molecule_bg.png") repeat-y 100% center/contain;
		}
	}

	&[data-index="0"],
	&[data-index="1"],
	&[data-index="5"] {
		width: 100%;
		height: 100vh;
	}
}
.fp-section {
	position: unset;
}

.section__inner {
	position: relative;
	width: 100%;
	height: 100%;
	pointer-events: none;

	& > * {
		pointer-events: auto;
	}
}

.section__1 {
	display: none;

	@media screen and (min-width: 600px) {
		display: block;
	}
}

.section__1 .section__inner {
	background-color: transparent;
}

.shape-wrap {
  position: absolute;
  top: 0;
	width: 100%;
	left: 0;
  bottom: 0;
  color: #0f3255m;
  display: flex;
  flex-direction: column;
  justify-content: center;
	pointer-events: none;
	z-index: 99;
	opacity: 0.3;
	display: none;

	@media screen and (min-width: 600px) {
		display: block;
	}
}

.mobile-spacer-top,
.mobile-spacer-bottom {
	pointer-events: auto;
	height: 25vh;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}

.mobile-spacer-bottom {
	top: auto;
	bottom: 0;
}

.mobile-spacer {
	position: relative;
	pointer-events: none;
	height: 100vh;

	@media screen and (min-width: 600px) {
		height: 0;
		pointer-events: none;
	}
}
.section__0 .mobile-spacer,
.section__1 .mobile-spacer,
.section__5 .mobile-spacer {
	height: 0;
}

.section__0 .content-wrap,
.section__1 .content-wrap,
.section__5 .content-wrap {
	color: #F3F3F3;
	background-color: transparent;
	min-height: 100vh;

	@media screen and (min-width: 600px) {
		display: flex;
		flex-direction: column;
		flex-direction: row;
		align-items: center;
		padding-top: 15vh;
		width: 90%;
		min-height: 0px;
		bottom: auto;
		left: 5vw;
	}

	@media screen and (min-width: 992px) {
		left: 70px;
		width: calc(100% - 140px);
	}

	@media screen and (min-width: 1400px) {
		width: calc(100% - 280px);
	}
}

.section__5 .content-wrap {
		padding-top: 8vh;

		p {

			@media screen and (min-width: 600px) {
				flex: 1 0 calc(50% - 40px);
				padding-left: 40px;
				margin-bottom: 0;
			}
		}

		h3 {
			font-size: 24px;
			line-height: 30px;
			font-weight: bold;

			@media screen and (min-width: 600px) {
				font-size: 30px;
				line-height: 34px;
			}

			@media screen and (min-width: 600px) {
				flex: 1 0 calc(50% - 40px);
				padding-right: 40px;
				font-size: 38px;
				line-height: 42px;
				margin-right: 0;
				margin-bottom: 0;
			}

			@media screen and (min-width: 1400px) {
				font-size: 42px;
				line-height: 48px;
			}
		}
}

.section__1 .content-wrap {
	width: 100%;
	left: 0;
	bottom: 0;
	padding-top: 40px;
	align-items: center;
	justify-content: space-between;

	@media screen and (min-width: 600px) {
		padding: 0px 15px;
		padding-top: 60px;
		align-items: self-start;
	}

	@media screen and (min-width: 768px) {
		padding: 0px 40px;
		padding-top: 75px;
	}

	@media screen and (min-width: 992px) {
		padding-top: 0;
		padding: 0px 70px;
		align-items: center;
	}

	@media screen and (min-width: 1400px) {
		padding: 0px 140px;
	}

}

.section__1 .content-wrap .left {
	width: 350px;
	text-align: left;

	padding-left: 0;

	@media screen and (min-width: 600px) {
		padding-right: 45px;
	}

	@media screen and (min-width: 1400px) {
		padding-right: 90px;
	}
}

.section__1 .content-wrap .right {
	width: 350px;
	text-align: left;

	@media screen and (min-width: 600px) {
		padding-left: 45px;
	}

	@media screen and (min-width: 1400px) {
		padding-left: 90px;
	}
}

.section__0 .content-wrap p {
	padding-left: 0;

	@media screen and (min-width: 600px) {
		padding-left: 60px;
	}
}
.section__0 {
	position: relative;
}
.lower-gradient {
	background: linear-gradient(rgba(243,243,243,0), black);
	position: fixed;
	bottom: -25vh;
	left: 0;
	width: 100%;
	height: 25vh;
	z-index: 99;
	pointer-events: none;
	opacity: 0;
	transition: all 0.5s 0s cubic-bezier(0.155, 0.81, 0.26, 0.995);

	&[data-active="0"] {
		opacity: 1;
		bottom: 0;
		transition: all 1s 0.5s cubic-bezier(0.155, 0.81, 0.26, 0.995);
	}
}

.fixed-scroll-button {
	position: fixed;
	padding: 0;
	bottom: 25px;
	right: 25px;
	z-index: 9999999999;
	cursor: pointer;
	opacity: 1;
	pointer-events: auto;
	transition: all 1s 0.5s cubic-bezier(0.155, 0.81, 0.26, 0.995);
	width: 40px;
	height: 40px;
	background: transparent;
	border-radius: 50%;
	box-shadow: 0 0 6px rgba(0,0,0,0.3);

	div {
		transition: all 1s 0.5s cubic-bezier(0.155, 0.81, 0.26, 0.995);

		&:hover {
			transition-delay: 0s;
			transition-duration: 0.25s;
			transform: scale(1.1);
		}
	}

	&[data-active="0"].up {
		opacity: 0;
	}

	&[data-active="5"].down {
		opacity: 0;
	}

	// &[data-active="5"] {
	// 	pointer-events: none;
	// 	opacity: 0;
	// 	transition: all 0.5s cubic-bezier(0.155, 0.81, 0.26, 0.995);
	// }

	&.up {
		bottom: 75px;
	}
	&.down {

	}
}

#fullpage {
	pointer-events: none;

	.section {
		pointer-events: none;
	}
}
.scroll-indicator {
	position: fixed;
	left: 5px;
	height: 80vh;
	top: 10vh;
	width: 5px;
	background-color: #E2E2E2;
	border-radius: 2.5px;
	transition: all 0.5s 0.5s cubic-bezier(0.155, 0.81, 0.26, 0.995);
	display: none;

	@media screen and (min-width: 600px) {
		display: block;
		left: 25px;
	}

	@media screen and (min-width: 1400px) {
		left: 45px;
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 5px;
		border-radius: 2.5px;
		background: linear-gradient(#D30F4B, #10384F);
		z-index: 1;
		height: 0%;
		opacity: 1;
		transition: all 0.5s cubic-bezier(0.155, 0.81, 0.26, 0.995);
	}
	&[data-active="0"],
	&[data-active="1"],
	&[data-active="5"] {
		opacity: 0;
		transition: all 0.5s cubic-bezier(0.155, 0.81, 0.26, 0.995);
	}
	&[data-active="2"]::after {
		background: linear-gradient(#10384F, #89D329);
		height: 25%;
	}

	&[data-active="3"]::after {
		background: linear-gradient(#10384F, #89D329);
		height: 50%;
	}

	&[data-active="4"]::after {
		background: linear-gradient(#89D329, #2789C1);
		height: 75%;
	}
	&[data-active="5"]::after {
		background: linear-gradient(#89D329, #2789C1);
		height: 100%;
	}
}

.section__0 .content-wrap h3 {
	width: 100%;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	flex: 1 0 100%;

	@media screen and (min-width: 600px) {
		max-width: 50%;
		flex: 1 0 50%;
	}
}
.section__0 .shape-wrap svg {
	width: 775px;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	bottom: -10%;
	overflow: visible;
	fill: #F3F3F3;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-weight: bold;
	text-align: center;
	font-size: 12px;
}

.content-wrap {
	position: relative;
  width: 100%;
  color: #0f3255;
	z-index: 99999;
	background-color: #F3F3F3;
	width: 100%;
	margin-left: 0;
	padding: 60px 25px 25px;
	pointer-events: auto;

	@media screen and (min-width: 600px) {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 56px;
		width: 40%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0px;
		padding-right: 15px;
		margin-left: 0;
		padding-bottom: 0px;
		z-index: 99;
		pointer-events: none;
		background-color: transparent;

		& > * {
			pointer-events: auto;
		}
	}

	@media screen and (min-width: 992px) {
		left: 70px;
		width: calc(50% - 70px);
	}

	@media screen and (min-width: 1400px) {
		left: 140px;
		width: calc(50% - 140px);
	}
}

.section__0 .content-wrap {
	min-height: 100vh;

	@media screen and (min-width: 600px) {
		min-height: 0px;
	}
}

h3 {
	font-size: 42px;
	line-height: 48px;
  font-weight: bold;
	margin-bottom: 40px;

	@media screen and (min-width: 600px) {
		margin-bottom: 20px;
	}

	@media screen and (min-width: 992px) {
		font-size: 58px;
		line-height: 58px;
		margin-right: -100px;
		margin-bottom: 40px;
	}

	@media screen and (min-width: 1400px) {
		font-size: 68px;
		line-height: 75px;
	}
}

h5 {
	font-size: 25px;
	line-height: 30px;
	font-weight: bold;
	margin-bottom: 8px;
}

p {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 20px;
	padding-left: 0px;

	@media screen and (min-width: 600px) {
		padding-left: 0px;
		// background-color: #F3F3F3;
		// padding-right: 12px;
		// padding-top: 12px;
	}

	@media screen and (min-width: 992px) {
		font-size: 16px;
		line-height: 24px;
		padding-left: 70px;
		// padding-right: 0;
		// padding-top: 0px;
		// background-color: transparent;
	}

	@media screen and (min-width: 1400px) {
		padding-left: 140px;
	}

}

.scroll-icon {
	display: none;
	// pointer-events: none;
	cursor: pointer;
	position: absolute;
	bottom: 200px;
	left: 25px;
	z-index: 2147483647;
	opacity: 1;
	pointer-events: auto;
	transition: all 1s 1s cubic-bezier(0.155, 0.81, 0.26, 0.995);

	@media screen and (min-width: 600px) {
		display: block;
		left: 50px;
	}

	@media screen and (min-width: 1400px) {
		left: 120px;
	}

	&[data-active="1"] {
		pointer-events: none;
		opacity: 0;
		transition: all 0.5s cubic-bezier(0.155, 0.81, 0.26, 0.995);
	}

	&[data-active="2"],
	&[data-active="3"],
	&[data-active="4"] {
		bottom: 50px;

		svg,
		text {
			fill: #073144;
		}
		line {
			stroke: #073144;
		}
	}
	&[data-active="5"] {
		pointer-events: none;
		opacity: 0;
		transition: all 0.5s $cubic-ease;
	}
}

.scroll-range-nav {
	position: absolute;
	left: 25px;
	top: 45%;
	height: auto;
	justify-content: space-between;
	width: calc(100% - 50px);
	transform: translateY(-50%);
	overflow: visible;
	border-radius: 2.5px;
	pointer-events: none;
	z-index: 99999;
	display: flex;
	opacity: 0;
	flex-direction: row;
	transition: all 0.5s $cubic-ease;

	&[data-active="5"] {
		pointer-events: auto;
		// display: flex;
		opacity: 1;
		transition: all 0.5s 0.5s $cubic-ease;
	}

	@media screen and (min-width: 600px) {
		flex-direction: column;
		height: 30vh;
		left: 70px;
		width: auto;
		top: 60%;
	}

	@media screen and (min-width: 1400px) {
		left: 140px;
	}

	.label {
		font-size: 11px;
		font-weight: bold;
		color: #F3F3F3;
		position: relative;
		width: auto;
		text-align: center;
		cursor: pointer;

		@media screen and (min-width: 600px) {
			font-size: 18px;
			text-align: left;
		}
	}
	.switch {
		position: relative;
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		margin: 8px 0;

		@media screen and (min-width: 600px) {
			flex-direction: row-reverse;
			justify-content: left;
		}
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: relative;
		display: block;
		cursor: pointer;
		background-color: rgba(243, 243, 243, 0.25);
		-webkit-transition: .4s;
		transition: .4s;
		width: 43px;
		height: 18px;
		margin-bottom: 24px;

		@media screen and (min-width: 600px) {
			margin-right: 44px;
			margin-bottom: 0;
		}
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 22px;
		width: 22px;
		left: 0;
		bottom: -2px;
		background-color: #F3F3F3;
		-webkit-transition: .4s;
		transition: .4s $cubic-ease;
	}

	input:checked + .slider {
		background-color: #89D329;
	}

	// input:focus + .slider {
	// 	box-shadow: 0 0 1px #89D329;
	// }

	input:checked + .slider:before {
		-webkit-transform: translateX(22px);
		-ms-transform: translateX(22px);
		transform: translateX(22px);
		box-shadow: 0 0 0px 6px rgba(243,243,243,0.25);
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 18px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
}

.cloud-overlay {
	position: fixed;
	top: 66%;
	transform: translate(-50%, -50%);
	right: auto;
	left: 50%;
	width: 50%;
	height: auto;
	z-index: 3;
	pointer-events: none;
	transition: all 1s $cubic-ease;
	display: none;

	@media screen and (min-width: 600px) {
		left: auto;
		transform: translateY(-50%);
		z-index: 999999;
		right: 0;
		top: 50%;

		&[data-active="0"] {
			top: 100%;
			right: auto;
			left: 50%;
			display: block;
			transform: translate(-50%, -50%);
			// animation: cloudfloat 20s linear infinite;
		}

		&[data-active="1"] {
			right: auto;
			left: 50%;
			transform: translate(-50%, -50%);
			display: block;
			transition: all 1s $cubic-ease;
		}
	}
}

.lat,
.lng {
	position: fixed;
	top: 60px;
	right: 60px;
	font-size: 65px;
	font-weight: bold;
	color: #000;
	opacity: 0.15;
	display: none;

	@media screen and (min-width: 600px) {
		display: block;
	}
}

.lng {
	top: 130px;
}

.scroll-prompt {
	position: fixed;
	top: auto;
	bottom: 60px;
	left: 50%;
	transform: translateX(-50%);
	color: #F3F3F3;
	font-size: 16px;
	text-transform: uppercase;
	opacity: 0.6;
	letter-spacing: 0.1em;
	z-index: 9999999;
}

.globe-wrap .scene-tooltip {
	background-color: #F3F3F3;
	max-width: 350px;
	width: 100%;
	// position: relative;
	z-index: 999;
}
.globe-wrap[data-active="5"] .scene-tooltip {
	display: none !important;
}
.globe-wrap .scene-tooltip p {
	margin: 0;
	padding: 30px;
	width: 100%;
	color: #10384F;
	font-size: 18px;
	line-height: 28px;
}

@keyframes cloudfloat {
	0% {
		transform: translate(-150%, -50%);
	}
	100% {
		transform: translate(150%, -50%);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn2 {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn3 {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn4 {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes bounce {
	0% {
		transform: translateX(0px);
	}
	50% {
		transform: translateX(10px);
	}
	100% {
		transform: translateX(0px);
	}
}
